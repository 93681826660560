<template>
  <div>
    <div class="iss-blocked">
      <div class="refresh-btn flex" >
        <div class="time">最新更新时间：{{ lastUpdateTime }}</div>

        <div class="updata" @click="updateContactFun">
          <SyncOutlined class="mr-10" v-if="!loadingT" />
          <a-spin class="mr-10" size="small" v-else />
          <span>更新粉丝状态</span>
        </div>
      </div>

      <div class="blocked-list">
        <grid
          ref="gridRef"
          :allow-selection="true"
          :columns="columns"
          :code="$route.path"
          :search="isSeach"
          :url="url"
          :url-params="{
            blackContact: true,
            appId: $store.state.account.appId,
          }"
          :btn-operation="btnOperation"
          :scroll="{ x: 1000, y: gridHeight }"
        >
        </grid>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs,watch ,createVNode,} from 'vue';
import Grid from '@/components/grid';
import wechatApi from '@/api/wechatServer';
import { SyncOutlined } from '@ant-design/icons-vue';
import { message,Spin,Modal, } from 'ant-design-vue';
import { useStore } from 'vuex';
import * as icons from '@ant-design/icons-vue';

export default {
  name: "blacked",
  components: {
    Grid,
    SyncOutlined,
    ASpin:Spin
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isSeach: {
      type: Object,
      default: () => ({}),
    },
    lastUpdateTime:{
      type:String,
      default:''
    },
    loadingT:{
      type:Boolean,
      default:false
    }
  },
  setup(props, context) {
    const store = useStore();
    const gridRef = ref();
    const state = reactive({
      isSeach:{},
      loadingT:false,
      lastUpdateTime: ''
    });
    const  updateList=()=>{
        gridRef.value.refreshGrid();
      }
    watch(
      ()=>  props.isSeach,
      (value) => {
        state.isSeach = value
      }
    )
    watch(
      ()=>props.lastUpdateTime,
      (value)=>{
        if(value){
          state.lastUpdateTime = value
        }
      }
    )
    watch(
      ()=>props.loadingT,
      (value)=>{
        if(value){
          state.loadingT = value
        }
      }
    )
    return {
      ...toRefs(state),
      gridRef,
      url: wechatApi.followedUrl,
      gridHeight: document.body.clientHeight - 419,
      columns: [
        {
          dataIndex: 'openId',
          title: 'openId',
          width: 220,
          ellipsis: true,
        },

        {
          dataIndex: 'blackTime',
          title: '最后加入黑名单时间',
          width: 200,
          ellipsis: true,
        },
      ],
      btnOperation: [
        {
          type: 'blacklist',
          label: '移除黑名单',
          btnType: 'primary',
          permission: 'resources:add',
          fnClick: selecItemList => {
            console.log(selecItemList,777)
            if (selecItemList?.length == 0) {
              message.error('请先勾选粉丝用户');
            } else {
            
          
              Modal.confirm({
                title: `确认移除黑名单吗？`,
                icon: createVNode(icons['ExclamationCircleOutlined']),
                okType: '',
                okText: '确认',
                onOk: () => {
                  //   加入黑名单接口
                  let data = {
                appId:store.state.account.appId,
                idList:selecItemList
              }
              wechatApi.removeBlack({}, data).then(()=> {
                gridRef.value.refreshGrid();
                message.success('移除成功');
              });

                },
              });
            }
          },
        },
      ],
      updateContactFun(){
        context.emit('updateFanStatus');
      },
      updateList,
     
    };
  },
};
</script>

<style scoped lang="less">
.iss-blocked {
  position: relative;
  margin-top: 26px;
  .refresh-btn {
    position: absolute;
    right: 20px;
    top: 2px;
    cursor: pointer;

    .updata {
      margin-left: 10px;
    }
  }
}
</style>
